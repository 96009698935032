import request from '@/utils/request'

export function getInvoice () {
    return request.get('/api/invoice/InvoiceHeaderController/get')
        .then(response => response)
}

export function updateInvoice (model) {
    return request.put('/api/invoice/InvoiceHeaderController', model)
        .then(response => response)
}

export function getFX (params) {
    return request.get('/api/invoice/InvoiceController/querySalaryToBeInvoice', {
        params: {
            ...params
        }
    })
        .then(response => response)
}

export function getHY (params) {
    return request.get('/api/invoice/InvoiceController/queryVipToBeInvoice', {
        params: {
            ...params
        }
    })
        .then(response => response)
}

export function getDataPage (params) {
    return request.get('/api/invoice/InvoiceController/queryPageByCondition', {
        params: {
            ...params
        }
    })
        .then(response => response)
}

export function getDetails (params) {
    return request.get('/api/invoice/InvoiceController/queryInfo', {
        params: {
            ...params
        }
    })
        .then(response => response)
}

export function add (model) {
    return request.post('/api/invoice/InvoiceController', model)
        .then(response => response)
}
